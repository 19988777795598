import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";
import { incrementMetric } from "@src/utils/logClient";

const Webview = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { link } = parsedLocation;

  useRedirectHandler(() => {
    const generateLink = () => {
      if (link && typeof link === "string") {
        const decodedLink = decodeURIComponent(link);
        return decodedLink;
      } else {
        incrementMetric("external_site.universal_link.fallback", { component: "Webview" });
        return `https://classdojo.com/#/`;
      }
    };
    const generatedURL = generateLink();
    const hasParams = generatedURL.includes("?");
    return `${generatedURL}${hasParams ? "&" : "?"}webview=true`;
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Webview;
